$(document).ready(function(){

	if($('.slider-products').length > 0){
		$('.slider-products').slick({
			asNavFor: '.slider-nav',
			centerMode: false
		});

		$('.slider-nav').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			asNavFor: '.slider-products',
			dots: true,
			centerMode: true,
			focusOnSelect: true,
			vertical: true
		});
	}


	$('.btn-update-item').click(function(e){
		e.preventDefault();

		var id = $(this).data('id'),
		href = $(this).data('href'),
		cantidad = $('#cant_product_' + id).val();

		window.location.href = href + '/' + cantidad;
	});

	$('#talla').change(function(){
		var talla = $(this).val(),
		href = $(this).data('href');


		$('#buy_button').attr('href', href + '/' +talla);
	});

	$('#add_descripcion').click(function(e){
		e.preventDefault();

		var parent = $(this).parents('.form-group'),
		clone = parent.clone(),
		button = clone.find('#add_descripcion'),
		text = clone.find('[type=text]'),
		hidden = clone.find('[type=hidden]');

		button.html('<i class="fa fa-btn fa-minus"></i> Eliminar');
		button.addClass('btn-danger');
		button.addClass('delete-element');
		button.attr('id', '');
		text.val('');
		hidden.val('');
		parent.parent().append(clone);
	});

	$('body').on('click', '.delete-element',function(e){
		e.stopPropagation();
		e.preventDefault();

		var parent = $(this).parents('.form-group');
		parent.remove();
	})

	$('.color_selector span').click(function(){
		var radio = $(this).find('input'),
		parent = radio.parents('.color_selector');


		parent.find('span').removeClass('active');
		$(this).addClass('active');
		radio.prop('checked', true);
	});

	$('#role').change(function(){
		console.log(this.value)
		if(this.value == 'entidad'){
			$('#nit').show();
			$('#cedula').hide();
		}else{
			$('#nit').hide();
			$('#cedula').show();
		}

	});

	$('.delete_item').click(function(e){
		e.stopPropagation();
		e.preventDefault();
		if(confirm("Está seguro de eliminar este usuario.")){
			window.location = this.href;
		}
		
	});
});
